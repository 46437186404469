import React from "react"
import ServiceSidebarArchitecture from "./ServiceSidebarArchitecture"
import details1 from "../../assets/images/services/banner-mobile-dev.jpg"

const MobileArchitectureContent = ({ location }) => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <ServiceSidebarArchitecture location={location} />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <span className="sub-title">SOFTWARE ARCHITECTURE DESIGN</span>

              <h3>Mobile Architecture</h3>
              <p>
                <h5>
                  Design for Mobile Android and iOS Apps using either native
                  development in Java/Kotlin/Swift or hybrid development for
                  both platforms with React-Native. Porting existing Cordova,
                  Phonegap Hybrid apps to native development or react-native.
                </h5>
              </p>

              <p>
                Mobile applications evolve and change very quickly. They are the
                frontend of your solution, and therefore have to change fast to
                fulfill user needs in an effective way. This has become even
                more important now, when companies prefer mobile first approach
                when launching new solutions.
              </p>
              <p>
                Research shows that mobile users value application
                functionality, speed, responsiveness, ease of usage and modern
                UX design. To stay competitive in the mobile field, an app has
                to combine all those aspects in a balanced way.
              </p>

              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="content">
                    <h3>Typical challenges in mobile projects we see:</h3>
                    <ul>
                      <li>
                        Lack of responsiveness in an app hinders good user
                        experience
                      </li>
                      <li>
                        Problems with wider device support. Not being aware of
                        existing compatibility problems
                      </li>
                      <li>
                        New versions of Android and iOS that can break working
                        app
                      </li>
                      <li>
                        Incompatibilities caused by various app versions
                        existing on user devices at the same time
                      </li>
                      <li>
                        Late notifications for mobile users in time critical
                        processes
                      </li>
                      <li>
                        Lack of A/B tests and selective user features switching
                      </li>
                      <li>
                        Longer development time for same features on both native
                        iOS/Android platforms
                      </li>
                      <li>
                        Unnecessary version updates compared to solution where
                        part of content and features are updated without rolling
                        out a newer version
                      </li>
                      <li>
                        No event logging for specific device groups to enable
                        diagnosis of user reported issues in production
                      </li>
                      <li>
                        Slow manual test runs when introducing new features to
                        solution
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <h5>
                If you encountered mentioned challenges don't hesitate to
                contact us.
              </h5>

              <h4 className="pt-30">
                What we can do to solve those challenges:
              </h4>
              <div className="content">
                <ul>
                  <li>
                    Porting of hybrid app to native development for faster
                    responsive user experience
                  </li>
                  <li>
                    Build in CI/CD test automation for supported devices and OS
                    versions
                  </li>
                  <li>
                    Build Mobile/Backend version management for data and API
                  </li>
                  <li>
                    Build Mobile/Backend Module for notification management,
                    monitoring and tracing
                  </li>
                  <li>
                    Build Mobile Feature A/B test management and bundle
                    installations
                  </li>
                  <li>
                    Optimise Development for both Android and iOS for faster
                    feature build
                  </li>
                  <li>
                    Optimisze app changes to minimize number of new releases in
                    app stores
                  </li>
                  <li>
                    Build event gathering module for better production support
                  </li>
                  <li>Perform an audit of current architecture solution</li>
                  <li>
                    Perform a basic audit for security - for more advanced
                    please check our Security Hardening Services
                  </li>
                  <li>Prepare a redesign of existing functionalities</li>
                  <li>Prepare architecture design for new features</li>
                  <li>
                    Prepare architecture design for mobile automatic tests in
                    CI/CD processes
                  </li>
                </ul>
              </div>
              <p>
                Although we are an IT architecture company, we can provide
                software development services in cases where solution complexity
                is high, and the use of highly skilled IT professionals is
                recommended.
              </p>
              <p>
                We also optimise standard development industry processes like
                CI/CD for mobile apps, pilot testing, quality control for
                features & security. For details please take a look on our
                Software Development Optimisation services.
              </p>

              <h3 className="pt-30">Technology</h3>
              <p>
                Technologies & tools that we typically use in our mobile
                projects.
              </p>
              <ul className="technologies-features">
                <li>
                  <span>Java</span>
                </li>
                <li>
                  <span>Kotlin</span>
                </li>
                <li>
                  <span>Swift</span>
                </li>
                <li>
                  <span>React-Native</span>
                </li>
                <li>
                  <span>Flutter</span>
                </li>
                <li>
                  <span>Ionic</span>
                </li>
                <li>
                  <span>Espresso</span>
                </li>
                <li>
                  <span>Detox</span>
                </li>
                <li>
                  <span>Appium</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MobileArchitectureContent
